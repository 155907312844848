<template>
    <div class="coupons-list">
      <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
      <div class="main-content">
        <div v-for="(item,index) in list" :key="index">
          <div class="title">{{item.title}}</div>
          <el-row :gutter="50">
            <el-col :span="7" v-for="(items,indexs) in item.itemList" :key="indexs" @click="tapPush(items.url)" v-has="items.tag">
              <div class="box_item">
                <div class="left" :style="{'backgroundColor':items.color}">
                  <img :src="items.icon"/>
                </div>
                <div class="right">
                  <p>{{items.title}}</p>
                  <p>{{items.lable}}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref } from 'vue'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { breadcrumbList } from './config'
  import { getServeBindList,unbindServe,bathUnbind } from "@/service/main/serve";
  import { useRouter } from 'vue-router'
  import { popup } from '@/utils/pop-up'
  import { ElMessage } from 'element-plus'
  
  export default defineComponent({
    props: {
  
    },
    components: {
      Breadcrumb,
    },
    setup() {
      const router = useRouter()
      const list = reactive(
        [{
          title:'营销玩法',
          itemList:[{
            lable:'发放代金券，吸引顾客消费',
            title:'现金券',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/abea61cf-1f8b-4421-bfb0-6155a82192a3.png',
            url:'/main/coupons/list?type=1',
            color:'#7948ea',
            tag:'coupons:one:list'
          },{
            lable:'凭券享受折扣优惠，合理控制活动成本',
            title:'折扣券',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/00b3e971-ccf2-4f8f-bbcf-ce621808a01b.png',
            url:'/main/coupons/list?type=2',
            color:'#7948ea',
            tag:'coupons:two:list'
          },{
            lable:'凭券兑换商品，吸引顾客体验',
            title:'兑换券',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/45df6ec9-b435-42ff-a2eb-0a15a417e680.png',
            url:'/main/coupons/list?type=3',
            color:'#7948ea',
            tag:'coupons:three:list'
          },{
            lable:'定向发券，吸引顾客消费',
            title:'发券宝',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/87d50983-883e-4945-bff9-e8c85db9c350.png',
            url:'/main/coupon/maketingCouponList',
            color:'#7948ea',
            tag:'give:list'
          },{
            lable:'邀请新人注册，即可享受更多奖励',
            title:'邀请有礼',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/1cc4beeb-9bb7-49a2-afd7-7149d62626ed.png',
            url:'/main/invite/list',
            color:'#7948ea',
            tag:'invite:list'
          },{
            lable:'邀请亲友绑定，即可共享资源',
            title:'亲友账户',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/8dcbc499-78e0-46d1-813b-25d3d4151618.png',
            url:'/main/family/list',
            color:'#7948ea',
            tag:'family:list'
          }]
        },{
          title:'第三方获客',
          itemList:[{
            lable:'绑定第三方团购商品，吸引顾客体验',
            title:'点评获客',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/79e0b0a9-8a2a-4832-ac42-2333672e66d4.png',
            url:'/main/bindshop/list',
            color:'#FEC302',
            tag:'bindshop:list'
          },{
            lable:'绑定第三方团购商品，吸引顾客体验',
            title:'抖音获客',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/805f1fde-1f85-4f3b-8e34-be74c8ff3677.png',
            url:'/main/bindTiktok/list',
            color:'#000',
            tag:'bindTiktok:list'
          }]
        },{
          title:'用户运营',
          itemList:[{
            lable:'积分制会员等级',
            title:'免费会员',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/2edab1eb-fdbf-4f47-9314-650c0213d4f2.png',
            url:'/main/member/integral/list',
            color:'#7948ea',
            tag:''
          },{
            lable:'付费会员尊享权益',
            title:'付费会员',
            icon:'https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/cc52bc2d-63ec-4a68-9441-d4792b9c6abc.png',
            url:'/main/member/paid/list',
            color:'#7948ea',
            tag:''
          }]
        }]
      )

      const tapPush = (url) =>{
        router.push(url)
      }
      const initPage = async () => {
        // const res = await getServeBindList({ ...queryInfo })
        // dataList.value = res.data.list
        // dataTotal.value = Number(res.data.total)
      }
      initPage()
  
  
  
      return {
        breadcrumbList,
        list,
        tapPush
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .coupons-list{
    height: calc(100vh - 90px);
  }
  .main-content {
    padding: 20px 80px 20px 20px;
    margin: 20px;
    background-color: #fff;
    .title{
      padding-top: 10px;
      margin-bottom: 40px;
    }
  }
  .box_item{
    width: 100%;
    height: 60px;
    background-color: #efefef;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 30px;
    cursor: pointer;
    .left{
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      border-radius: 6px;
    }
    img{
      width: 30px;
      height: 28px
    }
    .right{
      height: 42px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 12px;
    }
    .right :first-child{
      font-weight: 500;
      font-size: 14px;
    }
  }
  </style>
  
  
  
  
  